import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined, FlagOutlined } from '@ant-design/icons';
import logo from '../../../images/lux_adria-logo.svg';
import { de_DE, hr_HR } from '../../../common/constants';

export const Navbar: React.FC = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const menu = (
        <Menu onClick={changeLanguage}>
            <Menu.Item key={hr_HR} icon={<FlagOutlined />}>
                HRV
            </Menu.Item>
            <Menu.Item key={de_DE} icon={<FlagOutlined />}>
                DEU
            </Menu.Item>
        </Menu>
    );

    return (
        <section id="navbar">
            <div className="upper-navbar">
                <div className="logo">
                    <div>
                        <a href="/"><img src={logo} alt="logo" /></a>
                        <p>Lux Adria</p>
                    </div>
                </div>
                <Dropdown className="language-dropdown" overlay={menu}>
                    <Button className="language-button">
                        {t("chooseLanguage")} <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
            <div className="lower-navbar">
                <div onClick={() => setIsOpen(!isOpen)} className="hamburger">
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                </div>
                <div className={isOpen ? "links show-menu" : "links"} >
                    <Link onClick={() => setIsOpen(false)} className="link" to="/">{t("navbar.home")}</Link>
                    <Link onClick={() => setIsOpen(false)} className="link" to="/who-we-are">{t("navbar.who_we_are")}</Link>
                    <Link onClick={() => setIsOpen(false)} className="link" to="/real-estate-types">{t("navbar.buy")}</Link>
                    <Link onClick={() => setIsOpen(false)} className="link" to="/contact">{t("navbar.contact")}</Link>
                </div>
            </div>
        </section>
    );

    function changeLanguage(language: any): void {
        localStorage.setItem("i18nextLng", language.key);

        window.location.reload();
    }
};
